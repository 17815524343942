import CHECK_SHOPIFY_APP_EMBED_STATUSES from '@/graphql/CheckShopifyAppEmbedStatuses.gql';
import pollingMixin from '@/mixins/polling';

export default {
  mixins: [pollingMixin],
  data() {
    return {
      verificationStatus: null,
    };
  },
  methods: {
    async verifyAppEmbedStatuses(myshopifyDomains) {
      const {
        data: { appEmbedStatuses },
      } = await this.$apollo.mutate({
        mutation: CHECK_SHOPIFY_APP_EMBED_STATUSES,
        variables: {
          myshopifyDomains,
        },
      });
      return appEmbedStatuses;
    },
    async checkAppEmbedInstall(myshopifyDomain) {
      const [{ disabled, domain }] = await this.verifyAppEmbedStatuses([myshopifyDomain]);

      if (disabled === false) {
        this.verificationStatus = 'success';
      }

      return { disabled, domain };
    },
    async pollAppEmbedInstall(myshopifyDomain, { timeout = 30000 } = {}) {
      return new Promise((resolve) => {
        try {
          this.verificationStatus = 'ongoing';
          let failedTimeoutId = null;

          const { stopPolling } = this.startPolling(async () => {
            const result = await this.checkAppEmbedInstall(myshopifyDomain);

            if (result.disabled === false) {
              stopPolling();
              clearTimeout(failedTimeoutId);
              resolve(result);
            }
          });

          failedTimeoutId = setTimeout(() => {
            this.verificationStatus = 'failed';

            stopPolling();
            resolve({ disabled: true, domain: myshopifyDomain });
          }, timeout);
        } catch (err) {
          this.verificationStatus = 'failed';
          resolve({ disabled: true, domain: myshopifyDomain });
        }
      });
    },
  },
};
