<template lang="pug">
.brand-domain-integration
  component(:is="type" :class="`brand-domain-integration-status-svg-${statusClass}`")
  .brand-domain-integration-status(:class="`brand-domain-integration-status-${statusClass}`")
    i.fas(:class="`fa-${statusClass}`")
</template>
<script>
  import InsertCode from '@/components/Svg/InsertCode.vue';
  import Analytics from '@/components/Svg/Analytics.vue';

  export default {
    components: {
      InsertCode,
      Analytics,
    },

    props: {
      type: { type: String },
      isIntegrated: { type: Boolean, default: false },
    },

    computed: {
      statusClass() {
        if (this.isIntegrated) {
          return 'check';
        }
        return 'times';
      },
    },
  };
</script>

<style lang="sass">
  .brand-domain-integration
    position: relative
    &-status
      font-size: 0.5rem
      color: white
      background: #2CC898
      border-radius: 100%
      height: 0.875rem
      width: 0.875rem
      display: flex
      align-items: center
      justify-content: center
      font-weight: bold
      position: absolute
      top: -0.125rem
      right: -0.3125rem
      &-check
        background: #2CC898
      &-times
        background: #DC3545
      &-minus
        background: #AAB1C1
      &-svg
        &-check.insert-code-svg
          path
            fill: var(--brand-primary-color)
</style>
