<template lang="pug">
.container-fluid.px-3
  .row.mb-4
    .col
      om-heading.mt-1(h1) {{ $t('yourDomains') }}
  .row.no-gutters.mb-5.pb-4.bb-1
    .col-12.col-lg-10.mt-3
      .d-flex.align-items-start
        om-input#newDomain.w-30.mr-4(
          ref="newDomain"
          v-model.trim="newDomain"
          type="url"
          @keyup.enter="add"
          :placeholder="$t('domainTypeInNew')"
        )
          template(slot="error" v-if="$v.newDomain.$error && !$v.newDomain.isCool")
            span {{ $t('error.field.url') }}
        om-button.mr-4(primary @click="add") {{ $t('addNewDomain') }}
        .brand-input-clearable.w-15.ml-auto
          om-input#search(v-model="filter" type="text" :placeholder="$t('search')")
          .brand-input-clearable-icon(v-show="filter.length" @click="clearFilter")
            close-icon
    .col-12.col-lg-10(v-if="activeDomainLimitReached")
      .d-flex.align-items-start
        .form-text.text-danger
          span {{ $t('notifications.domainLimitReached') }}
  .row
    .col-12.col-lg-10.position-static
      loading-logo(v-if="loading")
      domains-table(
        v-else
        :domains="domains"
        :domainsCount="domainsCount"
        :pagination="pagination"
        :paginate="!filter.length"
        :filter="filter"
        @paginationChange="pagination = $event"
        @refetch="refetch"
      )
</template>

<script>
  import DomainsTable from '@/components/Tables/Domains.vue';
  import GET_DOMAINS_WITH_PAGINATION from '@/graphql/GetDetailedDomainsWithPagination.gql';
  import GET_ACCOUNT_LIMITS from '@/graphql/GetAccountLimits.gql';
  import ADD_DOMAIN from '@/graphql/AddDomain.gql';
  import { validateDomain, removeProtocolFromDomain } from '@/util';
  import { debounce } from 'lodash-es';
  import { mapState, mapGetters } from 'vuex';

  const _clone = (v) => JSON.parse(JSON.stringify(v));

  export default {
    components: {
      DomainsTable,
    },

    data() {
      return {
        loading: true,
        newDomain: '',
        domains: [],
        domainLimit: 0,
        domainsCount: 0,
        pagination: { page: 1, limit: 5 },
        filter: '',
      };
    },

    computed: {
      ...mapState(['account']),
      ...mapGetters(['activeDomainsCount']),
      activeDomainLimitReached() {
        return this.account.type !== 'sub' && this.activeDomainsCount >= this.domainLimit;
      },
    },

    validations: {
      newDomain: {
        isCool(v) {
          return validateDomain(v);
        },
      },
    },

    watch: {
      filter() {
        this.resetPagination();
        this.debounceFilterQuery();
      },
      pagination() {
        this.fetchDomains();
      },
    },

    async created() {
      this.debounceFilterQuery = debounce(() => {
        this.fetchDomains();
      }, 350);

      this.loading = true;

      if (this.$route.query.domain) {
        this.filter = this.$route.query.domain;
      }

      await Promise.all([this.fetchDomainLimits(), this.fetchDomains()]);

      this.loading = false;
    },

    methods: {
      async refetch() {
        this.filter = '';
        this.loading = true;
        await this.fetchDomains();
        this.$store.dispatch('fetchDomains');
        this.loading = false;
      },

      async fetchDomainLimits() {
        const {
          data: { accountLimits },
        } = await this.$apollo.query({
          query: GET_ACCOUNT_LIMITS,
        });

        this.domainLimit = accountLimits.limits.domains;
      },

      async fetchDomains() {
        const {
          data: { getDetailedDomainsWithPagination },
        } = await this.$apollo.query({
          query: GET_DOMAINS_WITH_PAGINATION,
          variables: {
            pagination: this.pagination,
            filter: this.filter,
          },
        });

        this.domains = _clone(getDetailedDomainsWithPagination.domains);
        this.domainsCount = getDetailedDomainsWithPagination.count;
      },

      async add() {
        this.$v.$touch();
        if (this.$v.newDomain.$invalid) {
          return;
        }

        const newDomainWithoutProtocol = removeProtocolFromDomain(this.newDomain);

        const {
          data: { addDomain },
        } = await this.$apollo.mutate({
          mutation: ADD_DOMAIN,
          variables: {
            domain: newDomainWithoutProtocol,
          },
        });

        if (addDomain._id) {
          this.newDomain = '';
          this.$store.commit('setInsertCodeStatus', false);
          this.$notify({
            type: 'success',
            text: this.$t('notifications.addSuccess'),
          });
          this.refetch();
        } else {
          this.$notify({
            type: 'error',
            text: this.$t(`notifications.${addDomain.message}`),
          });
        }

        this.$v.$reset();
      },

      resetPagination() {
        this.pagination = { page: 1, limit: this.pagination.limit };
      },

      clearFilter() {
        this.filter = '';
      },
    },
  };
</script>

<style lang="sass">
  .brand-input-clearable
    position: relative

    input
      padding-right: 2rem

    &-icon
      position: absolute
      right: 0.9375rem
      top: 50%
      transform: translateY(-50%)
      cursor: pointer
</style>
