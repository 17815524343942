import { getAccountIdFromCookie } from '@/util';

export default {
  computed: {
    newParam() {
      if (this.$route.params && this.$route.params.new) {
        return this.$route.params.new;
      }
      return undefined;
    },
  },
  methods: {
    redirectToCampaign() {
      if (this.$route.params.campaignId) {
        this.$router.push({
          name: 'campaign_variants',
          params: {
            userId: getAccountIdFromCookie(),
            id: this.$route.params.campaignId,
            new: this.newParam,
          },
        });
      } else {
        this.$router.push({ name: 'campaigns', params: { userId: getAccountIdFromCookie() } });
      }
    },
  },
};
