export default {
  methods: {
    startPolling(pollingFunc, { interval = 3000, timeout = 5000 } = {}) {
      let stopped = false;
      const stopPolling = () => {
        stopped = true;
      };

      const tick = async () => {
        if (stopped) {
          return;
        }

        let scheduledNextTick = false;
        const scheduleNextTick = () => {
          if (!scheduledNextTick && !stopped) {
            scheduledNextTick = true;
            setTimeout(tick, interval);
          }
        };

        const intervalTimeoutId = setTimeout(() => {
          scheduleNextTick();
        }, timeout);

        try {
          await pollingFunc({ stopPolling });
        } finally {
          clearTimeout(intervalTimeoutId);
          scheduleNextTick();
        }
      };

      // schedule first tick
      setTimeout(tick, interval);

      return { stopPolling };
    },
  },
};
