import runtimeConfig from '@/config/runtime';
import TOGGLE_SHOPIFY_APP_EXTENSION_STATUS from '@/graphql/ToggleShopifyAppExtensionStatus.gql';

export default {
  methods: {
    getAppExtensionDeeplink({
      shopifyDomain,
      extensionUuid = runtimeConfig.VUE_APP_SHOPIFY_APP_EXTENSION_UUID,
    }) {
      return `https://${shopifyDomain}/admin/themes/current/editor?context=apps&activateAppId=${extensionUuid}/app-embed`;
    },
    generateShopifyAppEmbedDeepLink(shopifyDomain) {
      const extensionUuid = runtimeConfig.VUE_APP_SHOPIFY_APP_EXTENSION_UUID;
      return this.getAppExtensionDeeplink({
        shopifyDomain,
        extensionUuid,
      });
    },
    async changeAppExtensionStatus(myshopifyDomain, enabled) {
      try {
        const {
          data: { result },
        } = await this.$apollo.mutate({
          mutation: TOGGLE_SHOPIFY_APP_EXTENSION_STATUS,
          variables: {
            myshopifyDomain,
            isActive: enabled,
          },
        });

        if (!result?.success) {
          this.$notify({
            type: 'error',
            text: this.$t(`notifications.shopifyAppExtension.${result?.message || 'failed'}`),
          });
        }

        return result;
      } catch (err) {
        this.$notify({
          type: 'error',
          text: this.$t('notifications.shopifyAppExtension.failed'),
        });

        return { success: false };
      }
    },
    openAppEmbedActivationOnShopify(shopifyDomain) {
      const link = this.generateShopifyAppEmbedDeepLink(shopifyDomain);
      window.open(link, '_blank');
    },
    async toggleShopifyAppExtension(myshopifyDomain, enabled) {
      if (!myshopifyDomain) return;
      if (enabled) {
        this.openAppEmbedActivationOnShopify(myshopifyDomain);
      }
      return this.changeAppExtensionStatus(myshopifyDomain, enabled);
    },
  },
};
