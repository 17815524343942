<template lang="pug">
om-modal(
  name="domain-inactivation-modal"
  :width="700"
  color="light"
  :scrollable="true"
  @closed="$emit('cancel')"
)
  template(slot="modal-header")
    .row
      .col
        .font-weight-bold.font-size-1--25.mb-4 {{ $t('domainInactivationWarningModal.title') }}
        div {{ $t('domainInactivationWarningModal.lead') }}
    .analytics-dashboard-modal-close(@click="$modal.hide('domain-inactivation-modal')")
      close-icon(:width="14" :height="14")
  template(slot="modal-body" v-if="domain")
    om-table(
      :items="domain.activeCampaigns"
      :columns="columns"
      :paginate="false"
      :selectable="false"
      :sortable="false"
    )
      template(slot="preview" slot-scope="{ rowData }")
        variant-image(
          :variant="getPreviewVariant(rowData.row)"
          :classes="{ v1: rowData.row.version === 1, [rowData.row.templateType]: true }"
          :templateName="rowData.row.templateName"
          :isNew="rowData.row.version !== 1"
        )
      template(slot="name" slot-scope="{ rowData }")
        .d-flex.align-items-center {{ rowData.row.name }}
  template(slot="modal-footer")
    .d-flex.justify-content-center
      .brand-btn.brand-btn-secondary.mr-3(@click="$emit('cancel')") {{ $t('cancel') }}
      .brand-btn.brand-btn-primary.mr-3(@click="$emit('inactivate')") {{ $t('domainInactivationWarningModal.inactivateBtn') }}
</template>

<script>
  import { getPreviewVariant } from '@/util';
  import VariantImage from '@/components/VariantImage.vue';

  export default {
    components: { VariantImage },
    props: {
      domain: { type: Object, default: null },
    },
    data() {
      return {
        columns: [
          { header: '', key: 'preview' },
          { header: this.$t('campaign'), key: 'name' },
          { header: this.$t('impressions'), key: 'impressions' },
          { header: this.$t('conversions'), key: 'conversions' },
        ],
      };
    },
    methods: {
      getPreviewVariant(campaign) {
        return getPreviewVariant(campaign);
      },
    },
  };
</script>
