<template lang="pug">
om-progressive-image.template-preview.template-preview-small.flex-shrink-0.cursor-pointer(
  :src="previewSrc"
  placeholder="/brand-skeleton.png"
  :class="{ ...classes, loading: loading }"
  :title="templateName"
  @click.native="$emit('click', $event)"
)
</template>

<script>
  import VARIANT_PREVIEWS from '@/graphql/VariantPreviews.gql';
  import { getPreviewForVariant } from '@/util';

  export default {
    props: {
      classes: {
        type: Object,
      },
      variant: {
        type: Object,
        default: null,
      },
      templateName: {
        type: String,
        default: null,
      },
      isNew: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        interval: null,
        previewSrc: '',
        loading: false,
      };
    },

    watch: {
      variant() {
        this.setLoadingAndPreviewSrc();
      },
      templateName() {
        this.setLoadingAndPreviewSrc();
      },
      loading(v) {
        if (v && !this.interval) {
          this.interval = setInterval(this.refetchPreviewImages, 5000);
        } else {
          this.loading = false;
        }
      },
    },

    mounted() {
      if (this.templateName) {
        this.setLoadingAndPreviewSrc();
      }
    },

    beforeDestroy() {
      this.stopCheck();
    },
    methods: {
      async refetchPreviewImages() {
        try {
          const {
            data: {
              variantPreviews: { previewURLs, previewGenerated, previewGeneratedAt },
            },
          } = await this.$apollo.query({
            query: VARIANT_PREVIEWS,
            variables: {
              variantId: this.variant._id,
            },
          });

          if (previewGenerated) {
            this.previewSrc = `${previewURLs[0]}?ts=${new Date(previewGeneratedAt).getTime()}`;
            this.loading = false;

            this.stopCheck();
          }
        } catch (e) {
          if (!e.networkError) {
            throw e;
          }
        }
      },

      setLoadingAndPreviewSrc() {
        if (!this.variant || !this.isNew) {
          this.loading = false;
          this.previewSrc = getPreviewForVariant({ templateName: this.templateName });
        } else {
          this.loading = this.variant.previewGenerated === false;
          this.previewSrc = getPreviewForVariant({ variant: this.variant });
        }
      },
      stopCheck() {
        clearInterval(this.interval);
        this.interval = null;
      },
    },
  };
</script>
<style lang="sass">
  .template-preview.sidebar
    background-size: 50% !important
    background-position: bottom left !important
</style>
