<template lang="pug">
div
  om-table.brand-domain-table.brand-full-table(
    ref="domainTable"
    :items="domains"
    :columns="columns"
    :columnClasses="columnClasses"
    :allItemsCount="domainsCount"
    :selectable="false"
    :paginate="paginate"
    :pagination="pagination"
    :show-header="false"
    :limits="[5, 15, 30, 50]"
    @paginationChange="$emit('paginationChange', $event)"
    :extendable="true"
  )
    template(slot="domain" slot-scope="{ rowData }")
      .d-flex.align-items-center
        .mr-3 {{ rowData.row.domain }}
        .d-flex.flex-grow-0.rename-setting-box
          i.fa.fa-pencil.rename-setting.cursor-pointer(@click.stop="rename(rowData.row)")
    template(slot="settings" slot-scope="{ rowData }")
      .d-flex.align-items-center.justify-content-end
        om-switch(
          :value="!rowData.row.inactive"
          :id="rowData.row._id"
          @click.native.stop=""
          @switch.self="toggleDomainActiveStatus(rowData.row, rowData.row.inactive)"
        )
        domain-integration-status(type="insert-code" :isIntegrated="isCodeInserted(rowData.row)")
        domain-integration-status(type="analytics" :isIntegrated="rowData.row.analytics.enabled")
        .d-flex.flex-grow-0.justify-content-end.mx-2
          .remove-setting-box
            i.fas.fa-trash-alt.remove-setting.cursor-pointer(@click.stop="remove(rowData.row._id)")
    template(slot="extendable" slot-scope="{ rowData }")
      .brand-full-table-extendable
        .card
          .card-body
            .brand-full-table-extendable-row
              insert-code-svg.mr-2
              .flex-grow-1
                template(
                  v-if="isActiveShopifyDomain(rowData.row.domain) && rowData.row.isShopifyAppExtensionActive"
                )
                  span.font-weight-bold(
                    v-if="getDomainAppEmbedStatus(myshopifyDomainOfDomain(rowData.row.domain)) === 'enabled'"
                  ) {{ $t('domainLevelSettings.insertCode.appEmbedTitle.enabled') }}
                  span.font-weight-bold(v-else) {{ $t('domainLevelSettings.insertCode.appEmbedTitle.disabled') }}
                    om-link.d-inline-block.ml-3.om-link.font-size-1.font-weight-bold(
                      primary
                      @click="navigateToShopifyCodeInsert(rowData.row.domain)"
                    ) {{ $t('domainLevelSettings.insertCode.howToEnable') }}
                template(v-else)
                  span.font-weight-bold {{ $t('domainLevelSettings.insertCode.title') }}
                  router-link.brand-link.ml-4(
                    v-if="!isCodeInserted(rowData.row)"
                    :to="{ name: 'settings-code-insert', query: { domain: rowData.row.domain } }"
                  ) {{ $t('domainLevelSettings.insertCode.help') }}
              .brand-full-table-extendable-row-last-part.d-flex.align-items-center.flex-grow-0.justify-content-center
                template(
                  v-if="isActiveShopifyDomain(rowData.row.domain) && rowData.row.isShopifyAppExtensionActive"
                )
                  .brand-full-table-extendable-code-inserted(
                    v-if="getDomainAppEmbedStatus(myshopifyDomainOfDomain(rowData.row.domain)) === 'enabled'"
                  )
                    span.om-domain-status.mr-2
                      i.far.fa-check-circle
                    span {{ $t('domainLevelSettings.insertCode.statuses.enabled') }}
                  .brand-full-table-extendable-code-not-inserted(v-else)
                    span.om-domain-status.mr-2
                      i.far.fa-times-circle
                    span {{ $t('domainLevelSettings.insertCode.statuses.disabled') }}
                template(v-else)
                  .brand-full-table-extendable-code-inserted(v-if="isCodeInserted(rowData.row)")
                    span.om-domain-status.mr-2
                      i.far.fa-check-circle
                    span {{ $t('domainLevelSettings.insertCode.statuses.inserted') }}
                  .brand-full-table-extendable-code-not-inserted(v-else)
                    span.om-domain-status.mr-2
                      i.far.fa-times-circle
                    span {{ $t('domainLevelSettings.insertCode.statuses.notInserted') }}
            .brand-full-table-extendable-row(
              v-if="isActiveShopifyDomain(rowData.row.domain) && !rowData.row.isShopifyAppExtensionActive"
            )
              div(style="margin-left: 36px")
              .flex-grow-1
                span(v-html="$t('domainLevelSettings.shopifyAppExtension.inactive')")
              .brand-full-table-extendable-row-last-part
                toggle-button(
                  :value="rowData.row.isShopifyAppExtensionActive"
                  @change.self="showAppEmbedConfirmationModal(rowData.row, rowData.row.isShopifyAppExtensionActive)"
                  :sync="true"
                  :labels="{ checked: $t('active'), unchecked: $t('inactive') }"
                  :width="90"
                  :height="30"
                  :margin="2"
                )
            .brand-full-table-extendable-row
              analytics-svg.mr-2
              .flex-grow-1(v-html="$t(`domainLevelSettings.analytics.title`)")
              .brand-full-table-extendable-row-last-part
                toggle-button(
                  v-model="rowData.row.analytics.enabled"
                  @change.self="toggleAnalyticsStatus(rowData.row._id, $event.value)"
                  :sync="true"
                  :labels="{ checked: $t('domainLevelSettings.analytics.statuses.enabled'), unchecked: $t('domainLevelSettings.analytics.statuses.disabled') }"
                  :width="90"
                  :height="30"
                  :margin="2"
                )
            .brand-full-table-extendable-row.configure-analytics(
              v-if="!isWhitelabelDomain && rowData.row.analytics.enabled"
            )
              img.mr-2(:src="require('@/assets/admin/svg/tag-manager.svg')")
              .ml-3
                .font-weight-bold.mt-2 {{ $t('domainLevelSettings.tagManager.title') }}
                .brand-full-table-extendable-row-text.my-3 {{ $t('domainLevelSettings.tagManager.lead') }}
                a.brand-link.tag-manager-link(href="/OM_GA4.json" download="OM_QA4.json")
                  om-button.mr-3.mb-2(secondary) {{ $t('domainLevelSettings.tagManager.container') }}
              .brand-full-table-extendable-row-last-part.ml-auto
                a.brand-link(:href="tagManagerUrl" target="_blank") {{ $t('analyticsSection.howToEnable') }}

  rename-domain-modal(@refetch="$emit('refetch')")
  app-embed-confirmation-modal(
    @activate="enableShopifyAppExtension($event.domain, $event.isActive)"
  )
  domain-inactivation-warning-modal(
    :domain="selectedDomain"
    @inactivate="inactivateDomain(selectedDomain._id)"
    @cancel="resetInactivationWarningModal"
  )
</template>
<script>
  import REMOVE_DOMAIN from '@/graphql/RemoveDomain.gql';
  import GET_DOMAIN_USAGE_COUNT from '@/graphql/GetDomainUsageCount.gql';
  import TOGGLE_ANALYTICS_STATUS from '@/graphql/ToggleAnalyticsStatus.gql';
  import TOGGLE_DOMAIN_ACTIVE_STATUS from '@/graphql/ToggleDomainActiveStatus.gql';
  import AppEmbedConfirmationModal from '@/components/Modals/AppEmbedConfirmation.vue';
  import RenameDomainModal from '@/components/Modals/RenameDomain';
  import DomainIntegrationStatus from '@/components/DomainIntegrationStatus.vue';
  import InsertCodeSvg from '@/components/Svg/InsertCode.vue';
  import DomainInactivationWarningModal from '@/components/Modals/DomainInactivationWarningModal.vue';
  import AnalyticsSvg from '@/components/Svg/Analytics';
  import { isWhitelabelDomain } from '@/config/url';
  import shopifyAppExtension from '@/mixins/shopifyAppExtension';
  import appEmbed from '@/mixins/appEmbed';
  import { codeInsertionStatus } from '@/util';

  import { mapGetters } from 'vuex';
  import { getBrandedClassString } from '@/components/Elements/Button';

  export default {
    components: {
      AppEmbedConfirmationModal,
      RenameDomainModal,
      DomainIntegrationStatus,
      InsertCodeSvg,
      AnalyticsSvg,
      DomainInactivationWarningModal,
    },
    mixins: [shopifyAppExtension, appEmbed],
    props: {
      domains: { type: Array, required: true },
      domainsCount: { type: Number, required: true },
      pagination: { type: Object, required: true },
      paginate: { type: Boolean, default: true },
      filter: { type: String },
    },

    data() {
      return {
        selectedDomain: null,
        columns: [
          { header: '', key: 'domain', sortable: false },
          { header: '', key: 'settings', sortable: false },
        ],
        columnClasses: {
          domain: 'text-left flex-grow-1',
          settings: 'flex-grow-0 ml-5',
        },
        shopifyDomainStatuses: [],
      };
    },
    computed: {
      ...mapGetters([
        'isActiveShopifyDomain',
        'myshopifyDomainOfDomain',
        'isSubUser',
        'getActiveShopifyShopSettings',
      ]),
      activeMyshopifyDomains() {
        return this.getActiveShopifyShopSettings.map((shop) => shop.myshopify_domain);
      },
      insertCodeHelpUrl() {
        if (this.$i18n.locale === 'hu') {
          return 'https://support.optimonk.hu/hc/hu/articles/206630069-Javascript-k%C3%B3d-beilleszt%C3%A9se-';
        }

        return 'https://support.optimonk.com/hc/en-us/articles/203021431-Inserting-the-OptiMonk-JavaScript-code';
      },

      tagManagerUrl() {
        if (this.$i18n.locale === 'hu') {
          return 'https://support.optimonk.hu/hc/hu/articles/207670559-Google-Tag-Manager-integr%C3%A1ci%C3%B3';
        }

        return 'https://support.optimonk.com/hc/en-us/articles/360019571279-Integrate-OptiMonk-with-Google-Analytics-via-Google-Tag-Manager';
      },

      isWhitelabelDomain,
    },

    watch: {
      filter() {
        this.$refs.domainTable.resetExtendables();
      },
      activeMyshopifyDomains() {
        this.verifyAppEmbedStatuses(this.activeMyshopifyDomains).then((statuses) => {
          this.shopifyDomainStatuses = statuses;
        });
      },
    },

    mounted() {
      this.verifyAppEmbedStatuses(this.activeMyshopifyDomains).then((statuses) => {
        this.shopifyDomainStatuses = statuses;
      });
    },

    methods: {
      getDomainAppEmbedStatus(shopifyDomain) {
        const status = this.shopifyDomainStatuses.find(({ domain }) => {
          return domain === shopifyDomain;
        });
        if (status?.disabled === true) {
          return 'disabled';
        }
        if (status?.disabled === false) {
          return 'enabled';
        }

        return null;
      },
      async remove(id) {
        const {
          data: { domainUsageCount },
        } = await this.$apollo.query({
          query: GET_DOMAIN_USAGE_COUNT,
          variables: {
            domainId: id,
          },
        });

        this.$modal.show('dialog', {
          title:
            domainUsageCount > 0
              ? this.$t('domainUsed', { count: domainUsageCount })
              : this.$t('areYouSure'),
          buttons: [
            {
              title: this.$t('yes'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: () => {
                this.$apollo
                  .mutate({
                    mutation: REMOVE_DOMAIN,
                    variables: {
                      domainId: id,
                    },
                  })
                  .then(({ data: { removeDomain } }) => {
                    if (removeDomain) {
                      this.$notify({
                        type: 'success',
                        text: this.$t('notifications.domainRemoveSuccess'),
                      });
                      // eslint-disable-next-line
                      this.filter = '';
                      this.$emit('refetch');
                    } else {
                      this.$notify({
                        type: 'error',
                        text: this.$t('notifications.domainRemoveError'),
                      });
                    }

                    this.$modal.hide('dialog');
                  });
              },
            },
            {
              title: this.$t('cancel'),
              class: getBrandedClassString({ secondary: true }),
              default: true,
            },
          ],
        });
      },

      async rename(row) {
        const alreadyAdded = (this.domains || [])
          .filter(({ _id }) => row._id !== _id)
          .map(({ domain }) => domain);

        this.$modal.show('rename-domain', {
          domainId: row._id,
          domain: row.domain,
          alreadyAdded,
        });
      },

      async toggleAnalyticsStatus(domainId, enabled) {
        this.$apollo.mutate({
          mutation: TOGGLE_ANALYTICS_STATUS,
          variables: {
            domainId,
            enabled,
          },
        });
      },
      showAppEmbedConfirmationModal(domain, isActive) {
        this.$modal.show('app-embed-confirmation', { domain, isActive });
      },
      async enableShopifyAppExtension(domain) {
        const myshopifyDomain = this.myshopifyDomainOfDomain(domain.domain);
        const isActive = !domain.isShopifyAppExtensionActive;

        const result = await this.toggleShopifyAppExtension(myshopifyDomain, isActive);
        if (!result?.success) {
          this.$emit('refetch');
        }
      },

      async toggleDomainActiveStatus(domain, active) {
        const domainId = domain._id;

        if (!active && domain.activeCampaigns.length > 0) {
          this.selectedDomain = domain;
          this.$modal.show('domain-inactivation-modal');
          return;
        }

        this.setDomainActiveStatus(domainId, active);
      },

      async setDomainActiveStatus(domainId, active) {
        try {
          const {
            data: { toggleDomainActiveStatus: result },
          } = await this.$apollo.mutate({
            mutation: TOGGLE_DOMAIN_ACTIVE_STATUS,
            variables: {
              domainId,
              active,
            },
          });

          if (!result.success) {
            this.$notify({
              type: 'error',
              text: this.$t(`notifications.${result.message}`),
            });
          }
          this.$emit('refetch');
        } catch (err) {
          console.error(err);
          this.$notify({
            type: 'error',
            text: this.$t('notifications.domainActivationFailed'),
          });
        }
      },
      isCodeInserted(row) {
        if (this.isActiveShopifyDomain(row.domain)) {
          if (row.isShopifyAppExtensionActive) {
            const myshopifyDomain = this.myshopifyDomainOfDomain(row.domain);
            return this.getDomainAppEmbedStatus(myshopifyDomain) === 'enabled';
          }

          return true;
        }
        return codeInsertionStatus(row) === 'accessible';
      },

      async inactivateDomain() {
        const domainId = this.selectedDomain._id;

        await this.setDomainActiveStatus(domainId, false);
        this.resetInactivationWarningModal();
      },
      navigateToShopifyCodeInsert(domain) {
        const myShopifyDomain = this.myshopifyDomainOfDomain(domain);
        if (!myShopifyDomain) {
          return;
        }
        return this.$router.push({
          name: 'settings-code-insert-platform-setup',
          params: {
            platform: 'shopify',
          },
          query: {
            domain: myShopifyDomain,
            step: 2,
          },
        });
      },
      resetInactivationWarningModal() {
        this.$modal.hide('domain-inactivation-modal');
        this.selectedDomain = null;
        this.$emit('refetch');
      },
      externalRedirectNewTab(url) {
        window.open(url, '_blank');
      },
    },
  };
</script>
<style lang="sass">
  .brand-domains-subpage
    body
      overflow-y: scroll
  .brand-full-table
    .tag-manager-link:hover
      text-decoration: none
    .remove-setting.fa-pencil
      font-size: 1rem
    .remove-setting.fa-trash-alt
      font-size: 1.25rem
    .brand-domain-integration
      margin: 0 1.25rem
      margin-bottom: 0 !important
    .brand-table
      .tbody
        .brand-table-tr
          font-size: 1rem
          min-height: 4rem
          padding: .5625rem 1.5625rem
          border: 1px solid #EBECEE
          margin-top: 20px
          border-radius: 3px
          &.brand-table-tr-no-b-mr
            border-bottom: 0
            border-radius: 3px 3px 0 0
            border-color: #aab1c1
            + div > .brand-full-table-extendable
              border-left-color: #aab1c1
              border-right-color: #aab1c1
              border-bottom-color: #aab1c1
    &-extendable
      padding: 0 1.875rem 1.5625rem
      border: 1px solid #EBECEE
      border-radius: 0 0 3px 3px
      box-shadow: 0 4px 8px #E9EFF4
      .card
        border: none
        .card-body
          padding: 0
      &-code-inserted,
      &-code-not-inserted
        display: flex
        align-items: center
        font-weight: 500
      &-code-inserted
        color: #2CC898
      &-code-not-inserted
        color: #DC3545
      &-row
        display: flex
        align-items: center
        padding: 1.25rem 0
        border-top: 1px solid #E9EFF4
        font-size: 15px
        &-text
          color: #9198A9
          line-height: 1.6
        svg.insert-code-svg path
          fill: var(--brand-primary-color)
        &:first-child
          border-top: 0

        &-last-part
          min-width: 10.375rem
          text-align: center

        .vue-js-switch
          border-color: #DC3545

          .v-switch-button
            background-color: #DC3545 !important
          .v-switch-label
            font-weight: bold
            font-size: 0.875rem
            &.v-right
              color: #DC3545 !important

        &.configure-analytics
          border-top: none
</style>
