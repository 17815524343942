<template lang="pug">
om-modal.app-embed-confirmation-modal(
  :name="name"
  width="420"
  color="light"
  @beforeOpen="beforeOpen"
  @closeClicked="close"
  :scrollable="true"
)
  template(#header-title)
    .row
      .col
        .title {{ $t('enableAppEmbed.confirmation.title') }}
  template(#modal-body)
    .divider
    om-body-text.description(bt400sm) {{ $t('enableAppEmbed.confirmation.description') }}
  template(#modal-footer)
    .d-flex.justify-content-end.buttons-wrapper
      om-button.cancel-btn(secondary @click="close") {{ $t('cancel') }}
      om-button.confirm-btn(primary @click="activate") {{ $t('enableAppEmbed.activate') }}
</template>

<script>
  import onboardingRedirect from '@/mixins/onboardingRedirect';

  export default {
    mixins: [onboardingRedirect],
    data: () => ({
      name: 'app-embed-confirmation',
      params: {},
    }),
    methods: {
      beforeOpen({ params: { domain, isActive } }) {
        this.params = { domain, isActive };
      },
      activate() {
        this.$emit('activate', this.params);
        this.$modal.hide(this.name);
      },
      close() {
        this.$modal.hide(this.name);
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .app-embed-confirmation-modal
    .title
      font-weight: 700
      font-size: 18px
      line-height: 24px
    .confirm-btn
      margin-left: 36px
    .divider
      position: relative
      width: 420px
      left: -2.857rem
      top: -24px
      height: 1px
      background: $om-gray-300
    .description
      max-width: 320px
</style>
